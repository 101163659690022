import { Button, Center, VStack, forwardRef } from '@chakra-ui/react';
import Icon from '@designs/primitives/common/icon';
import BadgeA from '@designs/primitives/typography/badge-a';
import { useHarmony } from '@hooks/use-harmony';
import { BottomMenuButtonProps } from '..';

const BottomMenuButtonD: React.FC<BottomMenuButtonProps> = forwardRef<BottomMenuButtonProps, 'button'>(
    ({ iconName, label, isActive, iconContainerProps, additionalElement, ...props }, ref) => {
        const { theme } = useHarmony();

        return (
            <Button variant="unstyled" position="relative" {...props} ref={ref}>
                <VStack spacing="4px" minW="60px">
                    <Center
                        m="8px 4px 4px"
                        p="4px"
                        borderRadius="8px"
                        bgColor={isActive ? theme.background.primary : 'transparent'}
                        boxShadow={isActive ? theme.dropShadow.shadow2 : ''}
                        {...iconContainerProps}
                    >
                        <Icon size={24} fill={isActive} color={isActive ? theme.icon.black : theme.icon.gray600}>
                            {iconName}
                        </Icon>
                    </Center>
                    <BadgeA color={isActive ? theme.text.body1 : theme.text.body2}>{label}</BadgeA>
                </VStack>
                {additionalElement}
            </Button>
        );
    }
);

export default BottomMenuButtonD;
