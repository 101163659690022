import NotificationButton from 'src/legacy/components/common/notification-button';
import { BlockHStack } from 'src/legacy/components/blockui';
import { useHarmony } from '@hooks/use-harmony';
import RoutesEnum from '@routes';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';
import { useCustomer } from '@hooks/use-customer/use-customer';
import ShoppingCartButton from '@legacy/designs/shopping-cart-button';

interface HeaderButtonsAProps {}

const HeaderButtonsA: React.FC<HeaderButtonsAProps> = () => {
    const { theme } = useHarmony();
    const { isAuth } = useCustomer();
    return (
        <BlockHStack alignItems="center" spacing="xs">
            <ShoppingCartButton />
            <NotificationButton link={isAuth ? RoutesEnum.My : RoutesEnum.Login}>
                <CustomIconButton size="25px" color={theme.black}>
                    account_circle
                </CustomIconButton>
            </NotificationButton>
        </BlockHStack>
    );
};

export default HeaderButtonsA;
