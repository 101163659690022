import { useDesignComponents } from '@hooks/use-design-components';

import { ButtonProps, CenterProps, LinkProps } from '@chakra-ui/react';
import CustomLink from '@components/custom-link';
import { Design } from '@type/harmony-config';
import { ComponentType, ReactNode } from 'react';
import BottomMenuButtonD from './designD/bottom-menu-button';

export interface BottomMenuButtonProps extends ButtonProps {
    isActive: boolean;
    iconName: string;
    label: string;
    href?: LinkProps['href'];
    iconContainerProps?: CenterProps;
    additionalElement?: ReactNode;
}

const BottomMenuButton: React.FC<BottomMenuButtonProps> = ({ href, ...props }) => {
    const Component = useDesignComponents<ComponentType<BottomMenuButtonProps>>({
        [Design.DesignD]: BottomMenuButtonD,
    });

    return (
        <CustomLink href={href}>
            <Component {...props} />
        </CustomLink>
    );
};

export default BottomMenuButton;
