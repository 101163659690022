import { useDesignComponents } from '@hooks/use-design-components';

import CONFIG from '@config';
import AvailableCouponIndicator from '@designs/primitives/main/bottom-menu/available-coupon-indicator';
import Logo from '@designs/primitives/main/header/logo';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import HeaderButtons from '@legacy/designs/header-buttons';
import { IThemeProps } from '@type/common';
import { Design } from '@type/harmony-config';
import ThemeUtils from '@utils/theme.utils';
import React, { useState } from 'react';
import { BlockHStack, Popover } from 'src/legacy/components/blockui';
import CustomIcon from './custom-icon';
import CustomImage from './custom-image';

interface HeaderProps {
    fixed?: boolean;
}

const Header: React.FC<HeaderProps> = ({ fixed = true }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { theme, designSettings } = useHarmony();

    const height = useDesignComponents<number>({
        [Design.DesignA]: 50,
        [Design.DesignB]: 52,
        [Design.DesignC]: 56,
    });
    const padding = useDesignComponents<string>({
        [Design.DesignA]: '10px 16px',
        [Design.DesignB]: '10px 16px',
        [Design.DesignC]: '8px 16px',
    });
    const borderBottom = useDesignComponents<string>({
        [Design.DesignA]: 'none',
        [Design.DesignB]: 'none',
        [Design.DesignC]: `1px solid ${theme.gray[50]}`,
    });

    return (
        <>
            <HeaderStyled className="top-navbar" fixed={fixed} borderBottom={borderBottom} height={height} padding={padding}>
                <Logo
                    spacing="4px"
                    rightSideElement={
                        CONFIG.linkedPage && (
                            <Popover
                                onOpenChange={(open) => {
                                    setIsOpen(open);
                                }}
                                zIndex={ThemeUtils.zIndex.header}
                                trigger={
                                    <StyledIcon size="18px" theme={theme}>
                                        {isOpen ? 'expand_less' : 'expand_more'}
                                    </StyledIcon>
                                }
                            >
                                <LogoPopover theme={theme} role="button">
                                    <StyledLink href={CONFIG.linkedPage} rel="noreferrer">
                                        <BlockHStack align="distribute" alignItems="center">
                                            <LogoContainer>
                                                <CustomImage src="/image/linked-page-logo.svg" alt="linked-page-logo" layout="fill" objectFit="contain" />
                                            </LogoContainer>
                                            <CustomIcon size="22px" color={theme.primary.default}>
                                                done
                                            </CustomIcon>
                                        </BlockHStack>
                                    </StyledLink>
                                </LogoPopover>
                            </Popover>
                        )
                    }
                />
                <HeaderButtons />
            </HeaderStyled>
            {designSettings?.isDesignA && <AvailableCouponIndicator />}
        </>
    );
};

export default Header;

const HeaderStyled = styled.header<HeaderProps & { height: number; padding: string; borderBottom: string }>`
    ${(props: HeaderProps) => (props.fixed ? `position: sticky;top: 0px;` : ``)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: ${({ height }) => `${height}px`};
    padding: ${({ padding }) => padding};
    z-index: ${ThemeUtils.zIndex.header};
    background-color: ${'white'};
    border-bottom: ${({ borderBottom }) => `${borderBottom}`};
`;

const LogoContainer = styled.div`
    position: relative;
    width: 100px;
    height: 26px;
    background: white;
`;

const LogoPopover = styled.div<IThemeProps>`
    width: 165px;
    background: ${({ theme }) => theme.background.config};
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin: 8px 16px;
    transform: translateX(-6%);
    cursor: pointer;
`;

const StyledIcon = styled(CustomIcon)<IThemeProps>`
    background: ${({ theme }) => theme.background.config};
    border: 1px solid ${({ theme }) => theme.gray[400]};
    border-radius: 12px;
`;

const StyledLink = styled.a`
    padding: 14px 8px;
    display: block;
`;
