import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import HeaderButtonsA from './designA/header-buttons';
import HeaderButtonsB from './designB/header-buttons';
import HeaderButtonsC from './designC/header-buttons';

export interface HeaderButtonsProps {}

const HeaderButtons: React.FC<HeaderButtonsProps> = () => {
    const Component = useDesignComponents<typeof HeaderButtonsA | typeof HeaderButtonsB | typeof HeaderButtonsC>({
        [Design.DesignA]: HeaderButtonsA,
        [Design.DesignB]: HeaderButtonsB,
        [Design.DesignC]: HeaderButtonsC,
    });
    return <Component />;
};

export default HeaderButtons;
