import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import ShoppingCartButton from '@legacy/designs/shopping-cart-button';
import RoutesEnum from '@routes';
import DomainUtils from '@utils/domain.utils';
import { BlockHStack } from 'src/legacy/components/blockui';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';
import NotificationButton from 'src/legacy/components/common/notification-button';

interface HeaderButtonsBProps {}

const HeaderButtonsB: React.FC<HeaderButtonsBProps> = () => {
    const { theme, settings } = useHarmony();
    const { extensionStatus } = useExtension();

    return (
        <BlockHStack alignItems="center" spacing="4px">
            {!(DomainUtils.isDmarket || DomainUtils.isNhPay) && settings?.hasNormalSalesType && <ShoppingCartButton color={theme.gray[300]} fill />}
            {extensionStatus?.notification.isEnabled && (
                <NotificationButton link={RoutesEnum.Notification}>
                    <CustomIconButton fill size="26px" color={theme.gray[300]}>
                        notifications
                    </CustomIconButton>
                </NotificationButton>
            )}
            {/* TODO: temp comment out */}
            {/* <Link href={RoutesEnum.Category}>
                <CustomIconButton fill size="26px" color={theme.grayscale[300]}>
                    menu
                </CustomIconButton>
            </Link> */}
        </BlockHStack>
    );
};

export default HeaderButtonsB;
