import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';
import Link from 'next/link';
import { ReactNode, useMemo } from 'react';
import useSWR from 'swr';
import { authClient } from '@api/auth/auth.client';
import { INotificationCollectionDto } from '@models/notification/responseDto/notification-collection.dto';
import AuthCacheKeys from '@api/auth/cache-keys';
import { useHarmony } from '@hooks/use-harmony';
import { useCustomer } from '@hooks/use-customer/use-customer';

interface NotificationButtonProps {
    link: string;
    children: ReactNode;
}

const NotificationButton: React.FC<NotificationButtonProps> = ({ link, children }) => {
    const { theme } = useHarmony();
    const { isAuth } = useCustomer();
    const { data } = useSWR<INotificationCollectionDto | undefined>(AuthCacheKeys.notificationsCacheKey(isAuth, AuthCacheKeys.NOTIFICATION_ALERT_QUERY), () =>
        isAuth ? authClient.getCustomerNotifications(AuthCacheKeys.NOTIFICATION_ALERT_QUERY) : undefined
    );
    const notifications = useMemo<number>(() => data?.isUnreadTotal || 0, [data]);

    return (
        <Link href={link}>
            <IconContainer>
                {children}
                {notifications > 0 && <Count theme={theme} />}
            </IconContainer>
        </Link>
    );
};

export default NotificationButton;

const IconContainer = styled.div`
    position: relative;
`;

const Count = styled.div<IThemeProps>`
    background: ${({ theme }) => theme.status.error};
    width: 6px;
    height: 6px;
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 600px;
    display: flex;
    align-items: center;
`;
