import { useDesignComponents } from '@hooks/use-design-components';

import { Box, BoxProps } from '@chakra-ui/react';
import { Design } from '@type/harmony-config';
import ThemeUtils from '@utils/theme.utils';
import { ComponentType } from 'react';
import BottomMenuD from './designD/bottom-menu';

export interface BottomMenuProps extends BoxProps {}

const BottomMenu: React.FC<BottomMenuProps> = (props) => {
    const Component = useDesignComponents<ComponentType<BottomMenuProps>>({
        [Design.DesignD]: BottomMenuD,
    });

    return (
        <>
            {/* 
                To make it behave similar to `sticky`,
                allocate space equal to the bottom menu height. 
            */}
            <Box bgColor={'inherit'} minH={`${ThemeUtils.bottomBarHeight}px`} />
            <Component {...props} />
        </>
    );
};

export default BottomMenu;
