import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import ShoppingCartButton from '@legacy/designs/shopping-cart-button';
import RoutesEnum from '@routes';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { BlockHStack, BlockIcon } from 'src/legacy/components/blockui';
import { HeaderButtonsProps } from '..';

const HeaderButtonsC: React.FC<HeaderButtonsProps> = () => {
    const { theme } = useHarmony();
    const { extensionStatus } = useExtension();
    const router = useRouter();

    return (
        <BlockHStack height="100%" alignItems="center" spacing="16px">
            {router.pathname !== RoutesEnum.Search && extensionStatus?.search.isEnabled && (
                <Link href={RoutesEnum.Search}>
                    <a>
                        <BlockIcon size="24px" color={theme.gray[800]}>
                            search
                        </BlockIcon>
                    </a>
                </Link>
            )}

            <ShoppingCartButton size="24px" color={theme.gray[800]} padding="0px" />
        </BlockHStack>
    );
};

export default HeaderButtonsC;
