import { Box, HStack } from '@chakra-ui/react';
import AvailableCouponIndicator from '@designs/primitives/main/bottom-menu/available-coupon-indicator';
import BottomMenuButton from '@designs/primitives/main/bottom-menu/bottom-menu-button';
import { useCustomer } from '@hooks/use-customer/use-customer';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import RoutesEnum from '@routes';
import ThemeUtils from '@utils/theme.utils';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { BottomMenuProps } from '..';

const BottomMenuD: React.FC<BottomMenuProps> = (props) => {
    const { theme } = useHarmony();
    const { extensionStatus } = useExtension();
    const { isAuth } = useCustomer();
    const t = useTranslations();
    const router = useRouter();

    return (
        <Box
            as="nav"
            pos="fixed"
            bottom="0"
            w="100%"
            maxW={`min(100vw, ${extensionStatus?.maxScreenWidth}px)`}
            h={`${ThemeUtils.bottomBarHeight}px`}
            bgColor={theme.background.white}
            boxShadow={theme.dropShadow.buttonBottom}
            zIndex={ThemeUtils.zIndex.floating}
            {...props}
        >
            <HStack spacing="0px" w="100%" px="36px" justify="space-between">
                <BottomMenuButton isActive={router.pathname === RoutesEnum.Home} iconName="home" label={t('home.main')} href={RoutesEnum.Home} />
                {extensionStatus?.search.isEnabled && (
                    <BottomMenuButton isActive={router.pathname === RoutesEnum.Search} iconName="search" label={t('common.search')} href={RoutesEnum.Search} />
                )}
                <BottomMenuButton
                    isActive={router.pathname === RoutesEnum.Category || router.pathname === RoutesEnum.CategoryDetail}
                    iconName="menu"
                    label={t('category.categories')}
                    href={RoutesEnum.Category}
                />
                <BottomMenuButton
                    isActive={router.pathname === RoutesEnum.My}
                    iconName="face"
                    label={t('home.mypage')}
                    href={isAuth ? RoutesEnum.My : RoutesEnum.Login}
                    iconContainerProps={{ ml: '0px' }}
                    additionalElement={<AvailableCouponIndicator />}
                />
            </HStack>
        </Box>
    );
};

export default BottomMenuD;
